<template>
  <div class="PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('recharge.default[3]')"
            left-arrow
            @click-left="$router.go(-1)"
    >
      <template #right>
        <van-icon name="./static/icon/task00.png" size="25" @click="onClickRight"/>
      </template>
    </van-nav-bar>
    <div class="box">
      <van-field readonly
                 v-model="bankInfo.coin_type"
                 @click="showPicker = true"
                 :label="$t('recharge.label[0]')"
                 :placeholder="$t('recharge.placeholder[0]')"
      />
      <van-popup v-model:show="showPicker" round position="bottom">
        <van-picker
                :columns="columns"
                @cancel="showPicker = false"
                @change="onChangeType"
        />
      </van-popup>
      <div style="display: flex;background-color: #191C23;">
        <van-field readonly
                v-model="bankInfo.coin_address"
                :label="$t('recharge.label[1]')"
                :placeholder="$t('bindAccount.placeholder[1]')"
        />
        <van-icon name="./static/icon/copy.png" @click="copyCode" style="margin-top: 15px;"/>
      </div>
      <textarea id="inputBox" style="position: absolute;top: 0;left: 0;opacity: 0;z-index: -10;"></textarea>
      <div class="QRCode">
        <img :src="bankInfo.qr_code" />
      </div>
      <!--        <div style="font-size: 16px;font-weight: bold">{{$t('recharge.label[2]')}} ({{bankInfo.coin_type}})</div>-->
      <div class="mygrid22">
        <div style="font-size: 15px;margin-bottom: 10px;font-weight: bold">{{$t('recharge.label[3]')}}({{bankInfo.coin_type}})</div>
        <div style="display: flex;">
          <van-field v-model="coinInfo.money" placeholder="0" style="width: 180px" />
          <div style="font-size: 14px;margin-top: 15px;margin-left: 10px;font-weight: bold">
            ≈ {{parseFloat(currPrice*coinInfo.money).toFixed(2)}} USDT
          </div>
        </div>
        <div style="width:90%;height:1px; background:#E0E0E0;margin-bottom: 20px;margin-left: 20px"></div>
      </div>

      <div class="mygrid33">
        <div style="font-size: 16px;font-weight: bold">{{$t('recharge.label[4]')}}</div>

        <div style="text-align: center">
          <van-uploader v-model="coinInfo.screenshots" max-count="1"
                        :after-read="afterRead"/>
        </div>
      </div>
      <div style="text-align: center;margin-top: 20px">
        <p class="btn" @click="submitRecharge">{{$t('recharge.default[4]')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import QRCode from "qrcodejs2";
  import axios from "axios";
  import { Toast } from "vant";
  import config from "@/config";

  export default {
    name: "mixRecharge",
    components: {},
    props: ["walletType"],
    data() {
      return {
        showPicker: false,
        columns:[],
        rechargeinfo:[],
        bankInfo:{},
        coinPrices:{},
        coinPrices0:{},
        currPrice:0,
        coinInfo: {
          //充币信息
          address: "",
          money: 0,
          screenshots: [],
        },
      };
    },
    computed: {
      cardList() {
        return data;
      },
    },
    watch: {},
    created() {

      this.$Model.GetCommonData((data) => {
        let currlist = data.currencylist;
        let types = data.rechargetype;
        this.rechargeinfo = types;
        for(var i=0;i<types.length;i++){
          let tt = types[i];
          let ctype = tt['coin_type'];
          this.columns.push(ctype);
          for (var k=0;k<currlist.length;k++){
            var kcurr = currlist[k];
            if (kcurr['name']==tt['bank_name']){
              this.coinPrices[ctype] = kcurr['price'];
              this.coinPrices0[ctype]= kcurr['price0'];
              break;
            }
          }
        }
        this.bankInfo = types[0];
        this.currPrice = this.coinPrices0[types[0]['coin_type']];
        this.coinInfo.money = 0;
      });
    },
    mounted() {
      // this.getQRUrl();
    },
    activated() {},
    destroyed() {
    },
    methods: {
      onClickRight(){
        this.$router.push("/user/rechargeList");
      },
      //复制码和金额
      copyCode(i) {
        var copyBox = document.getElementById("inputBox");
        copyBox.value = this.bankInfo.coin_address;
        copyBox.select();
        document.execCommand("Copy"); // 执行浏览器复制命令
        this.$Dialog.Toast(this.$t('recharge.label[5]'));
      },
      onChangeType(obj,value){
        for(var i=0;i<this.rechargeinfo.length;i++){
          let tt = this.rechargeinfo[i];
          if (value==tt['coin_type']){
            this.bankInfo = tt;
            this.currPrice = this.coinPrices0[value];
            break;
          }
        }
        this.showPicker = false;
      },
      submitRecharge() {
        if (this.coinInfo.money<=0){
          let msg = this.$t('recharge2[0]');
          this.$Dialog.Toast(msg);
          return;
        }else if (this.coinInfo.screenshots.length<=0){
          let msg = this.$t('recharge2[1]');
          this.$Dialog.Toast(msg);
          return;
        }
        this.coinInfo['coin_type'] = this.bankInfo.coin_type;
        this.coinInfo['address'] = this.bankInfo.coin_address;
        let postData = this.coinInfo;
        this.$Model.RechargeOrder(postData, (data) => {
          let msg = this.$t('taskOrder2[3]');
          switch (data.code) {
            case 1:
              msg = this.$t('common3[0]');
              break;
            case -1:
              msg = this.$t('withdraw2[6]');
              break;
            default:
              msg = data.code_dec;
              break;
          }
          this.$Dialog.Toast(msg);
          if (data.code == 1) {
            this.$router.push("/user");
          }
        });
      },
      afterRead(file) {
        file.status = 'uploading'
        file.message = this.$t('upload[0]')
        this.uploadImgs(file)
      },
      uploadImgs(file) {
        if (!file.file.type.match(/image/)) {
          file.status = "failed";
          file.message = this.$t("upload[1]");
          return;
        }
        console.log(2);
        this.compressImg(file);
      },
      compressImg(file) {
        this.$Util.CompressImg(file.file.type, file.content, 750, (image) => {
          let param = new FormData();
          param.append("token", localStorage["Token"]);
          param.append("type", 3);
          param.append("image", image, file.file.name);
          this.$Model.UploadImg(param, (data) => {
            if (data.code == 1) {
              file.message = this.$t("upload[2]");
              file.status = "success";
              file.url = data.url;
              // this.submitTask();
              //显示提现弹窗
              console.log("upload success");
            } else {
              file.status = "failed";
              console.log("upload false");
              // file.message = this.$t("upload[3]");
            }
          });
        });
      },
    },
  };
</script>

<style scoped>
  /* .van-nav-bar--fixed,.Site .van-nav-bar{
      background-color: #4e51bf;
  } */
  .PageBox {
    color: #d7d7d7;
    background-color: #13171A;
  }

  .van-nav-bar {
    background-color: #191C23;
  }
  .van-nav-bar>>>.van-nav-bar__title{
    color:#fff;
    font-weight: bold;
  }
  .PageBox .van-cell>>>.van-cell__title{
    font-size: 16px;
    font-weight: bold;
    color: #fff;
  }


  .box >>> .van-uploader__upload {
    background-color: #13171A;
    border-radius: 4px;
  }

  .PageBox>>>.van-field__control {
    color: #fff;
  }
  .PageBox .box {
    width: 100%;
    padding: 0 19px;
    font-size: 19px;
    margin-top: 15px;
    color: #d7d7d7;
  }

  .box .balance {
    color: #000;
    font-size: 20px;
    margin-bottom: 15px;
  }

  .box .balance span {
    color: #4e51bf;
    padding-left: 5px;
  }

  .box .toRegister {
    font-size: 14px;
    color: #a0a0a0;
    text-transform: uppercase;
    border-bottom: 1px dashed #4e51bf;
  }

  .box .selectBox {
    border-bottom: 1px dashed #4e51bf;
  }

  .selectBox .select {
    /* width:; */
    max-width: 365px;
    display: flex;
    margin: 8px auto;
    flex-wrap: wrap;
  }

  .van-cell {
    background-color: #191C23;
  }

  .PageBox .box >>> .van-cell__title{
    color: #d7d7d7;
  }
  .selectBox .select p {
    padding: 10px 15px;
    border-radius: 4px;
    font-size: 16px;
    background-color: #dcf8ff;
    border: 1px solid #4e51bf;
    color: #4e51bf;
    margin: 8px 25px 12px 0;
  }
  .selectBox .select p:last-child {
    margin-right: 0px;
  }
  .van-cell__value {
    display: flex;
    color:#fff;
    justify-content: flex-end;
  }
  p.active {
    background-color: #4e51bf !important;
    color: #fff !important;
  }
  p{
    width: 80px;
  }

  .mygrid22{
    margin-top: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: #191C23;
    padding: 10px;
    overflow: hidden;
    width: 100%;
    position: relative;
    z-index: 2;
  }

  .mygrid33{
    margin-top: 10px;
    margin-bottom: 30px;
    border-radius: 5px;
    background-color: #191C23;
    padding: 10px;
    overflow: hidden;
    width: 100%;
    position: relative;
    z-index: 2;
  }

  .selectAmount .amount {
    margin-top: 20px;

  }


  .selectAmount .select {
    display: flex;
    justify-content: space-around;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: 8px auto;
    flex-wrap: wrap;
  }

  .selectAmount .select p {
    padding: 8px 25px;
    border-radius: 4px;
    font-size: 16px;
    background-color: #f4f5ff;
    /* border: 1px solid #4e51bf; */
    color: #4e51bf;
    margin-top: 10px;
    margin-left: 5px;
  }

  .informationBox {
    border-bottom: 1px dashed #c9c9c9;
  }

  .information-title {
    margin: 40px 0 34px 0;
  }

  .information {
    display: flex;
    font-size: 18px;
    align-items: center;
    margin-bottom: 26px;
  }

  .information input {
    flex: 1;
    height: 40px;
  }

  .QRBox .QR-title {
    margin-top: 20px;
  }

  .QRBox .upload {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    margin: 15px 0 0px 0;
  }

  .QRBox .upload .QRCode {
    width: 130px;
    height: 130px;
  }

  .QRBox .upload .QRCode img {
    width: 100%;
    height: 100%;
  }

  .QRBox .upload .imgBox {
    border: 1px solid #c9c9c9;
    padding: 30px;
  }

  .QRBox .infoBox {
    font-size: 18px;
  }

  .QRBox .infoBox .address {
    margin-top: 30px;
  }

  .QRBox .infoBox .code {
    margin-top: 2px;
    color: #4e51bf;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .QRBox .infoBox .code > span:first-child {
    flex: 1;
    word-break: break-all;
  }

  .QRBox .infoBox .copy {
    display: inline-block;
    padding: 8px 10px;
    border-radius: 6px;
    margin-left: 20px;
    color: #fff;
    background-color: #4e51bf;
  }

  .QRBox .infoBox .recharge {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
  }

  .QRBox .infoBox .recharge .price {
    padding-left: 10px;
  }

  .QRBox .infoBox .recharge .copy {
    display: inline-block;
    padding: 8px 10px;
    border-radius: 6px;
    margin-left: 20px;
    color: #fff;
    background-color: #4e51bf;
  }

  .QRBox .infoBox .order {
    margin: 20px 0 30px 0;
  }

  .QRBox .infoBox .account {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .QRBox .infoBox .account input {
    width: 150px;
    height: 40px;
    border-radius: 4px;
    background-color: #dcf8ff;
    border: 1px solid #4e51bf;
    padding-left: 10px;
    font-size: 20px;
  }

  .QRBox .upload .btn {
    padding: 10px 30px;
    border-radius: 6px;
    background-color: #4e51bf;
    color: #fff;
    text-align: center;
    margin: 41px 0 70px 0;
  }

  .description {
    font-size: 20px;
    color: #555;
    background-color: #f3f3f3;
    padding: 40px 19px;
  }

  .description p {
    color: #666;
    font-size: 18px;
    margin-bottom: 8px;
  }

  .description .title {
    color: #555;
    font-size: 20px;
    margin-bottom: 30px;
  }

  .van-loading {
    position: fixed;
    z-index: 99999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .popUpWindows {
    width: 50%;
    min-width: 280px;
    max-width: 400px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
    border-radius: 4px;
    background-color: #fff;
  }
  .popUpWindows .warmHint {
    display: block;
    width: 50px;
    height: 50px;
    margin: 30px auto 0 auto;
  }
  .popUpWindows .title {
    text-align: center;
    color: #4e51bf;
    font-size: 22px;
    line-height: 60px;
  }
  .popUpWindows .content {
    width: 100%;
    padding: 15px 20px 30px 20px;
    color: #000;
    text-align: center;
  }
  .popUpWindows .btn {
    display: flex;
    padding: 30px 20% 30px 20%;
    justify-content: space-between;
    color: #fff;
  }
  .popUpWindows .btn > p {
    line-height: 30px;
    text-align: center;
    background-color: rgb(211, 211, 211);
    width: 44%;
    border-radius: 4px;
  }
  .popUpWindows .btn > p:nth-child(2) {
    background-color: rgb(128, 128, 255);
  }
  .masking {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(90, 90, 90, 0.6);
    z-index: 3;
  }
  .infoBox >>> .van-cell-group::after {
    content: none;
  }
  .infoBox >>> .van-cell-group .van-field {
    border-bottom: 1px solid #4e51bf;
  }
  .van-button__text {
    white-space: nowrap;
  }

  .tool {
    display: flex;
    justify-content: center;
  }

  .withdraw {
    width: 150px;
    height: 43px;
    color: #fff;
    line-height: 40px;
    text-align: center;
    border: 1px solid #4f51c0;
    background: #4f51c0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .withdraw img {
    width: 25px;
    height: 25px;
    margin-right: 5px;
    vertical-align: middle;
  }

  .recharge {
    width: 150px;
    height: 43px;
    line-height: 40px;
    border: 1px solid #4f51c0;
    text-align: center;
    background: #fff;
    border-radius: 10px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .recharge img {
    width: 25px;
    height: 25px;
    margin-right: 5px;
    vertical-align: middle;
  }
  .withdrawBg {
    background: #fff;
    color: #696969;
  }
  .rechargeBg {
    background: #4f51c0;
    color: #fff;
  }
  .btn {
    width: 85%;
    padding: 10px 50px;
    border-radius: 15px;
    background-color: #0076FA;
    color: #fff;
    font-size: 18px;
    text-align: center;
    margin: 15px 30px 30px;
  }
</style>
